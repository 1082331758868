<template>
  <div class="expired-document-container">
    <img class="expired-document-image" src="../../../assets/no-search-results.png" />
    <span class="expired-link-header">{{$t("EXPIRED_LINK_HEADER")}}</span>
    <span class="expired-link-text">{{$t("EXPIRED_LINK_TEXT")}}</span>
    <div class="back-to-home-button" @click="navigateHome">
      <span>{{$t("BACK_TO_HOME")}}</span>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  name: "ExpiredDocument",
  setup() {
    const router = useRouter();
    const navigateHome = () => router.push("/");
    return {
      navigateHome
    }
  }
}
</script>

<style scoped>
.expired-document-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.expired-document-image {
  width: 33%;
  margin: 100px 0;
}

.expired-link-header {
  font-weight: bold;
  font-size: 50px;
  line-height: 68px;
  text-align: center;

  color: #2E293D;
  margin-bottom: 12px;
}

.expired-link-text {
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  /* identical to box height, or 33px */

  text-align: center;
  letter-spacing: 0.2px;

  color: #BABABA;
  margin-bottom: 30px;

}

.back-to-home-button {
  cursor: pointer;
  padding: 9px 36px;
  background: #627DFF;
  border-radius: 8px;
}
.back-to-home-button span {
  font-weight: 600;
  font-size: 16px;
  line-height: 138.8%;
  text-align: center;

  color: #FFFFFF;
}

@media screen and (max-width: 992px) {
  .expired-document-image {
    width: calc(100% - 130px);
    margin: 106px 0 30px;

  }

  .expired-link-header {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 7px;
  }

  .expired-link-text {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 30px;
    display: block;
    width: calc(100% - 104px);
  }

  .back-to-home-button {
    padding: 20px 45px;
    border-radius: 16px;
    margin-bottom: 50px;
  }

  .back-to-home-button span {
    font-size: 15px;
    line-height: 20px;
  }
}
</style>